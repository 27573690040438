.header {

    padding-top: 1.5rem;
    background: url(../assets/courbe-header.png) $color-alt;
    background-attachment: fixed;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 rem-calc(70);

        @include breakpoint (1200px down) {
            margin: 0 rem-calc(25);
        }

        @include breakpoint (768px down) {
            flex-direction: column;
            justify-content: center;
        }
    }

    #logo {
        width: 70%;

        @include breakpoint (768px down) {
            width: 100%;
        }
    }

    &__site-tel {
        @include breakpoint (1200px down) {
            display: none;
        }

        a {
            font-family: $third-font;
            font-weight: $medium;
            font-size: rem-calc(18);
            color: #000;
            position: relative;
            z-index: 100;
            transition: all .3s ease-in-out;
            display: flex;

            i {
                transform: scale(0);
                transition: all .3s ease-in-out;
                color: white;
            }

            &:after {
                content: '';
                left: 0;
                background: white;
                width: 100%;
                height: rem-calc(12);
                display: block;
                top: rem-calc(12);
                position: absolute;
                z-index: -1;
                transition: all .3s ease-in-out;
            }

            &:hover {
                color: #000;
                &:after {

                    height: rem-calc(3);
                    top: rem-calc(25)
                }

                i {
                    transform: scale(1);

                }

            }
        }
    }



    &__burger-nav {
        @include breakpoint (1200px down) {
            display: none;
        }
    }

    &__burger-nav .img-svg {
        cursor: pointer;
        /*   position: relative; */
        position: fixed;
        z-index: 9999999;
        width: rem-calc(40);
        /*  right: 40px;
        top: 40px; */
        top: 15px;
        right: 50px;
        transition: transform .3s ease-in-out;

        &:before {
            content: '';
            display: block;
            width: rem-calc(76);
            height: rem-calc(76);
            background-color: white;
            position: absolute;
            left: 50%;
            top: 40%;
            z-index: -1;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border-radius: 50%;


        }
    }

    &__headline {
        max-width: rem-calc(1350);
        margin: rem-calc(100) auto rem-calc(30);

        @include breakpoint (1200px down) {
            max-width: 100%;
            padding-right: rem-calc(45);
        }

        @include breakpoint (1024px down) {
            padding: rem-calc(15);
        }

        @include breakpoint (768px down) {
            margin: rem-calc(40) auto;
        }

        h1 {
            font-size: rem-calc(72);
            line-height: rem-calc(80);
            letter-spacing: -0.03em;
            color: white;
            font-weight: $extrabold;
            position: relative;
            padding-left: 8%;
            text-align: left;
            margin-bottom: rem-calc(80);

            @include breakpoint (1200px down) {
                padding-left: rem-calc(130);
                font-size: rem-calc(58);
                line-height: rem-calc(58);
            }

            @include breakpoint (1024px down) {
                padding: 0;
            }

            @include breakpoint (768px down) {
                font-size: rem-calc(48);
                line-height: rem-calc(48);
                margin: 0;
            }

            &:before {
                content: url(../assets/quote.png);
                display: block;
                position: absolute;
                left: 0;

                @include breakpoint (1200px down) {
                    display: none;
                }
            }
        }

        .title-intern {
            display: block;
            margin: 0;
            padding-bottom: 40px;
        }
    }


}


.upslider {
    margin-bottom: rem-calc(-10);

    .slick-list {
        padding-top: 60px;
        margin-top: -60px;
    }

    @include breakpoint (1200px down) {
        max-width: rem-calc(768);
        margin: auto auto -7px;
    }

    @include breakpoint (768px down) {
        max-width: rem-calc(475);
    }

    &__img {
        img {
            margin: auto;
            border-radius: rem-calc(5);
            box-shadow: 0px -29px 30px rgba(0, 0, 0, 0.2);

            @include breakpoint (768px down) {
                box-shadow: none;
            }
        }
    }

    &__zoom {
        text-align: center;
        [data-close] {
            color: white;
        }
    }

    .slick-arrow {
        margin: auto;
        position: absolute;
        text-indent: -9999px;
        width: rem-calc(46);
        height: rem-calc(46);
        top: 30%;
        cursor: pointer;
        z-index: 9;

        @include breakpoint (767px down) {
            display: none !important;
        }
    }

    .slick-prev.slick-arrow {
        left: 3%;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAe1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////++/TqxBr6893146rx2oztzVXpvwDv0WrryD7257jz35zw1nzdIeYdAAAAHHRSTlMACPrdue/mck8eEFvZzUq1jODXmBebi8+0SZ5cDOIFYgAAATpJREFUSMeVltlygzAMRYXZzZqNJO0FAkna/v8Xdtq0Q4Jkg88jcxAaIUumOTqpg22oVLgN6kSTFS+rFJ5QQeaZ5TgCI4oNL5x9iPhnKfQORnbsA/sCFor9q53msJKnL/YGC2yefK/AIsWU/wErOPzbJVZR/qXir9P9RzoxVhL/Bo9goJ33w0/4DDL92PWzRxkRBQa7G1vMCIi0Eu0PwYbSlIj25TpAIKEGAp+yjZpO4AzXC0Qqynn9vjqDjZxCZk8FZITEHo1TJgxFcImuKOT+zZh7SLlLZXI6QfQHQyFrl79aUwLZ726t1ARare9IpacGZv793vMGpgwmWPR3dvhsRJ7b0XYcHG5jyWnovU0j9bhsHz3ngT35vt32U7dl47jKOKVpUZZua9iy5AO+5K3opKkeV4iq4VeIb6AgsuxRdsMpAAAAAElFTkSuQmCC') no-repeat;

        @include breakpoint (1200px down) {
            left: -13%;
        }
    }

    .slick-next.slick-arrow {
        right: 3%;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////++/T6893146jqxBrx2ozpvwDtzVXryD7v0Wr257jw1nxPvGMrAAAAHHRSTlMACPrdue/m2VtPH7WMckrgz82YGBEQnItxSbSeeZQTYgAAAS1JREFUSMeVltl2gkAMQMMswMjiBqI2IAq2//+HPa161JkEyX2+hMySZMCndrlJrFI2MbmrYZIozRS+oEwa8XIRY0BcMB8cNJLoAxV6gyyb4AfbNU6w3r7bC42T6MWbvcQPLF/8aIUfWT3z3+EMdg+7xFmU91T0PF3f0tnjTPb/weO5evwXPkWPlvVTADC+fe1PjG4AaoU+5wvjqwocIuGfad9BgwQnxs/hiKTfX6kVZ5AgSdcPhJ+ARcYfxw59LCBHO/SBrwBZusvYBbqVRLeQsLkPHbHUo2RnMsiZff9pyWNyklN1xBXj75iqAAxlsxcY0nADv7nyoIqv5YtPVNqFsHHI2pKo6X0JW6qsYcvGgWzYCEdZSMkOSuEY5oe8IYc8T+Wa7PaEyBpXgccv7w6zDGRk4L4AAAAASUVORK5CYII=') no-repeat;

        @include breakpoint (1200px down) {
            right: -13%;
        }
    }
}

.upslider__img [data-open] {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAhCAYAAABTERJSAAABsUlEQVR42uzXzyvDcRzH8c3mV4sRdjNHOUyzg1ZOOLoYUhw4ODoph7m4+gv8AeQikWQHlBynSGY5oRZi0fyYUGie37xXo7TP9P0U+n7q0Wd99/5+vq+++34+n33tmUzG9ltake0XNSuMFeZPh3EWWO9CJwYRQAXuEccCIkj/OI2xzihqwmrmo93iBHEcIyXHNxEoYMxPVAsbcSAXXEQX3HBI34FZvCCBVl1hXNiQIFN5aselLoo6HWFCcoElOBXqp6V+TEeYZXlG2hUH9eMO6/ITKodRmdp+JBFVnBOn2IUPDWavM26Zvk+KYz5K+CqUmx3mQdaTMsUxS1Ep5z2bHeYQNWhWHNOLIGI4NzvMPGoxAodCfUjuzBquzV6Bq7EnC9ooir+ps6Mfr9hHva4VOIiznIWvRUKWyPT1YRJvUnOEbl1hbLLkb8vF0ohgBiu4kuMxXMpno+/TFcbgRVg2xJTcCaPfwoTsYQM5G+cFenWFyfLI7twmvefL98O4yQnUozOMiiHZRoyWVHmGdIaxS6DsHUrkO8ep8V+k8XY4J30YO/lOsFtvlFYYK8x/DPMuwACLPEyKtS210gAAAABJRU5ErkJggg=='), auto !important;
}





//Colours
$black: #0e0e0e;
$orange: #fcb852;
$grey: #dedede;
$white: #fff;




// FLASHY SLIPPY NAV STYLES
.mobile-nav-button {
    width: 35px;
    position: absolute;
    margin: 2rem;
    right: 0;
    top: 0;
    z-index: 9999;
    cursor: pointer;
    width: 35px;
    height: 30px;

    .mobile-nav-button__line {
        width: 100%;
        height: 4px;
        background: $black;
        position: relative;
        transition: 1s ease;

        &:nth-of-type(2) {
            margin: 0.5rem 0;
        }

    }

    .mobile-nav-button__line--1 {
        transform: rotate(45deg);
        top: 13px;
        position: absolute;
    }

    .mobile-nav-button__line--2 {
        display: none;
    }

    .mobile-nav-button__line--3 {
        transform: rotate(135deg);
        top: 13px;
        position: absolute;
    }
}

//Mobile Menu

.mobile-menu {
    display: block;
    max-width: 500px;
    width: 100%;
    right: -100%;
    height: 100vh;
    background: $primary-color;
    position: absolute;
    z-index: 9998;
    transition: 0.6s ease;
    top: 0;
    opacity: 0;

    ul {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;

        li {
            list-style: none;
            display: flex;
            margin: auto;
            max-width: 75%;
            margin: rem-calc(10) 0;

            a {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                display: block;
                text-align: left;
                text-decoration: none;
                color: white;
                font-size: rem-calc(25);
                line-height: rem-calc(30);
                font-weight: $bold;
                overflow: hidden;
                position: relative;
                padding:rem-calc(10 0 10 20);

                &:after {
                    content: '';
                    background: white;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    right: -100%;
                    top: 0;
                    z-index: -1;
                    transition: 0.4s ease;
                }

                &:hover {
                    &:after {
                        right: 0;
                    }

                    color:$secondary-color;
                }
            }
        }
    }

    img {
        position: absolute;
        width: 150px;
        display: block;
        left: 50%;
        top: 3rem;
        transform: translatex(-50%);
        padding: 0;
        text-align: center;
    }
}

.mobile-menu--open {
    right: 0;
    opacity: 1;
    z-index: 99999;
    position: fixed;
    top: 0;
}





.bloc_logo a {
    position: relative;
    display: inline-block;
    z-index: 1;

    a {
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
    }
}

#section2 {
    padding: rem-calc(250 0 200);
    background: white;

    @include breakpoint (1200px down) {
        padding: rem-calc(100 0);
    }

    .section__title, .section__title > * {
        @include big-titles;
    }

    .section__text {
        ul {
            @extend .no-bullet;
        }

        p,
        li {
            font-size: clamp(rem-calc(18), 5vw, rem-calc(30));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(40));
            letter-spacing: -0.03em;
            font-weight: $semibold;
            text-align: left;
            color: $color-txt;

            span {
                color: $color-txt;
            }
        }
    }
}
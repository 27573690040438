// Utils needed for the settings
@import '~foundation-sites/scss/global';
@import '~foundation-sites/scss/foundation';
@import '_configuration';

@import '_mixins';
@import '_common';
@import '_fonts';
@import '_social';
@import '_refnat';
@import '_rte';
@import '_print';
@import '_tarteaucitron';

// Importer les modules manuellement

@import 'modules/header';   
@import 'modules/general';   
@import 'modules/accordion';   
@import 'modules/sections';   
@import 'modules/section2';   
@import 'modules/section3';   
@import 'modules/section4';   
@import 'modules/section5';   
@import 'modules/section6';  
@import 'modules/section7';    
@import 'modules/section8';   
@import 'modules/section9';   
@import 'modules/footer';    
@import 'modules/numbers';    
@import 'modules/widget-contact';   
@import 'modules/widget-scrolltop';   


.accordion-item {
    border-bottom: solid 1px #dadada;
}
.accordion-title {
    font-weight: bold;
    &:hover, &:focus {
        color: $color-main;
    }

    &::before {
        font-size: 48px;
        font-weight: 100;
        top: 25%;
        right: 0;
    }
}
.is-active .accordion-title::before {
    top: 19px;
    font-size: 35px;
}
#section6 {

    @include breakpoint (medium up) {
        .animate_to_up {

            transform: translate(0px, 430px);
        }
    }

    padding-top:rem-calc(160);
    background:url(../assets/courbe-header.png) $color-alt;
    background-attachment: fixed;
    overflow: hidden; // Hide scrollbars 

    @include breakpoint (1440px down) {
        padding-right: rem-calc(40);
    }

    @include breakpoint (960px down) {
        padding: rem-calc(60 0 0);
    }

    .section__title,
    .section__title>* {
        @include big-titles;
        color: white;
    }


    .section6-left {
        padding-bottom: rem-calc(80);
        p {
            @include paragraphes;
            font-size: clamp(rem-calc(20), 5vw, rem-calc(24));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(36));
        }

        @include breakpoint (large down) {
            padding-bottom: rem-calc(60);
        }

        @include breakpoint (medium down) {
            padding-bottom: rem-calc(30);
        }

        .button {
            border: solid 7px $color-main;
            background: white;
            color: $color-main;

            @include breakpoint (medium down) {
                display: table;
                margin: auto;
            }

            &:hover {
                background: $color-main;
                border: solid 7px white;
                color: white;
            }
        }
    }

    .section6-right {
        text-align: right;
        position: relative;

        @include breakpoint (large up) {
            right: -3%;
        }

        img {
            text-align: right;
            border-top-left-radius: rem-calc(5);
            border-top-right-radius: rem-calc(5);
        }
    }

    .evoolutions-container {
        align-items: flex-end;
    }

    .toggle-text-container p {
        margin: 0;
    }

    button.toggle-text-b {
        font-size: 36px;
        display: flex;
        margin-left: auto;
    }
}
.footer {
    background: $grisclair;
    padding:rem-calc(60 40 10);
    @include breakpoint(large down) {
        padding:rem-calc(60 40 80);
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        max-width: rem-calc(960);
        gap:rem-calc(25);
        margin: auto;
        margin-bottom: rem-calc(25);
        @include breakpoint (768px down) {
            grid-template-columns:1fr;
            text-align: center;
        }
    }
    &__cols {
        margin:0 auto;
        &:first-child {
            margin:auto;
        }
        ul {
            margin: 0;
            list-style: none;
        }

        li {
            margin: 0 rem-calc(10);
            display: inline;
        }

        .fab {
            color:$color-txt;
            transition:color 300ms;
            &:hover {
                color:$primary-color;
            }
        }
        h4 {
            font-size: rem-calc(18);
            font-weight: $bold;
            color:$primary-color;
        }
        p {
            font-size: rem-calc(14);
            line-height: rem-calc(22);
            font-weight: $regular;
            letter-spacing: 0.05em;
            color:$color-txt;
        }
    }
    &__solution {
        font-weight: $bold;
        transition:300ms;
        &:hover {
            color:$primary-color;
        }
    }
    &__copyrights p {
        font-size: rem-calc(12);
        margin-bottom: 0;
        a {
            &:hover {
                color:$primary-color;
            }
        }
    }    
    a {
        color:$color-txt;
    }
}



.footer__menu {
    bottom: 0;
    width: 100%;
    left:0;
    right: 0;
    position: fixed;
    background:$primary-color;
    padding:rem-calc(10);
    z-index: 10;
    &--left {
        place-content: center;
        p {
            font-size: rem-calc(16);
            color:white;
            line-height: rem-calc(18);
            font-weight: $semibold;
            margin:auto;
            margin-left:rem-calc(10);
        }
    }
    @include breakpoint(large up) {
        display: none;
    }
    &--right {
        place-content: center;
        a {
            margin:auto;
        }
    }
}
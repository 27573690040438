#section8 {
    padding: rem-calc(160) 0 0;
    .button  {
            background: white;
    }
    @include breakpoint (1200px down) {
        padding: rem-calc(100 0 0);
    }

    @include breakpoint (960px down) {
        padding: rem-calc(60 0 0);
    }

    .section__title,
    .section__title>* {
        @include big-titles;
    }

    .prices__left {
        padding-right: 5%;

        @include breakpoint (1200px down) {
            padding-right: 0;
        }
    }

    .prices__accordeon {
        @include breakpoint (1200px down) {
            padding-right: rem-calc(40);
        }

        @include breakpoint (1023px down) {
            padding-right: 0;
        }
    }

    .accordion {
        background: transparent;

        ul {
            margin: 0;
            list-style: none;
            margin-bottom: rem-calc(10);

            li {
                padding: 10px 0;
                border-bottom: solid 1px #dadada;
                .cross {
                    text-indent: -999999px;
                    background:url(../images/coche.png) no-repeat;
                    width: rem-calc(16);
                    height: rem-calc(11);
                    background-position: center;
                    background-size: contain;
                }
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    /*.accordion-item:first-child> :first-child {
        padding-top: 0 !important;
    }*/

    .accordion-item.is-active {
        border-bottom: 0;

        .accordion-title {
            border-bottom: solid 1px #dadada;
        }
    }

    .accordion-content {
        background: transparent;
        border: 0;
        padding: 0;

        p {
            @include paragraphes;
        }
    }

    .accordion-title {
        border-bottom: 0;
    }


    .prices__right {
        img {
            box-shadow: 0px -38px 37px -7px #b9b9b9;
        }
    }


    .accordion .accordion-content li {
        display: flex;
        justify-content: space-between;
    }

}
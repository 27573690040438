html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-variant-numeric: lining-nums;
}

.background-grey {
  background-color: $grisclair;
}

.grid-container.interne,
.interne .grid-container {
  max-width: rem-calc(1200);
}

a.button.secondary.tiny {
  color: white;

  &:hover {
    background: $primary-color;
    color: white;
  }
}

.subtitles {
  font-size: rem-calc(14) !important;
  line-height: rem-calc(24) !important;
  color: $primary-color !important;
  text-transform: uppercase;
  font-weight: $bold !important;
  letter-spacing: 0.025em;
}


a {
  color:$primary-color;
  transition:color 300ms;
  &:hover, &:focus{
    color:$secondary-color;
  }
}


.bottomslider,
.voonooslider {
  .slick-dots {
    list-style: none;
    margin: rem-calc(30) 0;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 10px;
      text-align: center;
      place-content: center;
      display: inline-block;
      justify-content: center;
      align-items: initial;

      &.slick-active {
        button {
          background: $primary-color ;
          border-color: $primary-color;
        }
      }

      button {
        background: transparent;
        border: solid 2px black;
        width: rem-calc(20);
        height: rem-calc(20);
        border-radius: rem-calc(100);
        font-size: 0;
      }
    }
  }
}

.slick-next,
.slick-prev {
  transition: transform .3s ease-in-out;
  &:hover {
    transform: scale(1.08);
  }
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.flex-video {
  height: auto;
  padding: 0;
   video {
    width: auto;
    height: 600px;
    margin: auto;
    display: block;
    position: relative;
    left: 0;
    right: 0;
  }
}


@media screen and (min-width: rem-calc(1024)) {
  .toggle-text {
    display: none;
  }
}
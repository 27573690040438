.widget-contact {
    opacity: 0;
    position: fixed;
    top: 50%;
    background-color: $primary-color;
    max-width: rem-calc(415);
    right: rem-calc(-425);
    color: white;
    z-index: 10;
    padding: rem-calc(41) rem-calc(51) rem-calc(55) rem-calc(51);
    font-family: $alt-font;
    font-weight: $light;
    font-size: rem-calc(14);
    border-radius: 3px;

    @include breakpoint (large up) {
        transform: translateY(-50%);
        transition: right .3s ease-in-out, opacity 1s ease-in-out;
    }

    &.open {
        right: 0;
        opacity: 1;

        @include breakpoint (medium down) {
            padding: rem-calc(20);
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            max-width: none;
            max-height: 100vh;
            overflow-y: scroll;
        }

    }


    form {
        margin-top: rem-calc(20);
    }


    &__title {
        font-weight: $semibold;
        font-size: rem-calc(24);
        line-height: rem-calc(30);
    }

    form {
        margin-left: -$global-margin;
        margin-right: -$global-margin;
    }

    label {
        color: #fff;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    select,
    textarea {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #7f73e1;
        color: #fff;
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(15);
        padding: rem-calc(15) rem-calc(15) rem-calc(15) rem-calc(15);
        width: 100%;
        font-weight: $light;
    }

    select {
        background-color: #e7e4ff;
        color: #252525;
        padding: rem-calc(5) rem-calc(30) rem-calc(5) rem-calc(15);
        border-radius: 3px;
        margin-top: rem-calc(30);
        height: 50px;
    }

    input[type="checkbox"] {
        margin: 0;
    }

    #rrgpd {
        display: inline;
        width: 100% !important;
        margin-top: rem-calc(10);

        label {
            font-size: 11px;
            line-height: 16px;
            margin: 0;
            padding: 0;
            text-align: justify;
            margin-top: 10px;
        }

    }

    .seccode {
        min-height: 40px;
    }

    button[type="submit"] {
        background-color: $primary-color;
        font-weight: $bold;
        font-size: rem-calc(16);
        width: 100%;
        border: 8px solid white;
        border-radius: rem-calc(30);
        margin-top: rem-calc(-10);
        transition: all .3s ease-in-out;

        &:hover,
        &:focus {
            animation: pulse .5s;
        }
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 white;
            border: 8px solid white;
        }

        50% {
            box-shadow: 0 0 50px white;
            border: 8px solid $primary-color;
        }

        100% {
            box-shadow: 0 0 0 0 white;
            border: 8px solid white;
        }
    }

    input::placeholder {
        color: white;
        font-size: rem-calc(14);
        line-height: rem-calc(30);
        font-weight: $regular;
    }

    // Gestion des erreurs
    // Cacher les form-errors (ca fait trop et le champ est déjà en erreur)
    .form-error.is-visible {
        display: none !important;
    }

    // Dans le widget les erreurs sont en blanc
    .is-invalid-input {
        border-bottom: 3px solid $white !important;
    }

    .is-invalid-input::placeholder {
        color: $white;
    }

    [data-confirmation] {
        @extend .callout;
        display: none;
        position: absolute;
        background-color: #139ee0;
        color: #FFF;
        border: 0;
        padding: 1rem;
        margin-right: 1rem;
        cursor: pointer;

        &::before {
            content: "";
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid #139ee0;
            position: absolute;
            top: -19px;
            left: 25px;
        }
    }


}

.caller {
    &.open {
        position: fixed;
        right: rem-calc(-285);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        padding: rem-calc(10);
        background-color: $primary-color;
        color: white;
        z-index: 9999;
        transition: all .3s ease-in-out;
        font-family: $alt-font;
        font-weight: $bold;
        font-size: rem-calc(18);
        border-radius: 5px;
        width: rem-calc(610);
        text-align: center;
        cursor: pointer;

        @include breakpoint (1200px down) {
            display: none;
        }
    }

    right: rem-calc(-320);
    transform: translateY(-50%) rotate(-90deg);
    position: fixed;
    transition: transform .3s ease-in-out;
}

.close {
    top: 6px;
    position: absolute;
    right: 16px;
    font-size: 25px;
    cursor: pointer;
}
#section3 {
    padding: rem-calc(165 0 0);
    @include breakpoint(1200px down) {
        padding: rem-calc(100 0 0);
    }
    @include breakpoint(960px down) {
        padding: rem-calc(60 0 0);
    }

    @include breakpoint(1024px down) {
        .slick-slide{
            min-height: rem-calc(205);
        }
    }

    @include breakpoint(1023px down) {
        .slick-slide{
            min-height:0;
        }
    }


    .section__title, .section__title > * {
            @include big-titles;
            @include breakpoint(1200px down) {
                br {
                    display: none;
                }
            }
            @include breakpoint(959px down) {
                padding: 0 rem-calc(20);
            }
    }
    .subtitles {
        @include breakpoint(959px down) {
            padding: 0 rem-calc(20);
        }
    }
    .voonoo__desktop {
        @include breakpoint(960px down) {
            display: none;
        }
    }
    .voonoo__accordeon {
        .accordion {
            background: transparent;
        }
        .accordion-number {
            font-size: clamp(rem-calc(50), 5vw, rem-calc(100));
            line-height: clamp(rem-calc(40), 5vw, rem-calc(80));
            letter-spacing: -0.03em;
            font-weight: $semibold;
            font-family: $third-font;
            text-align: left;
            color: $color-alt;
            float: left;
            width: rem-calc(80);
            @include breakpoint(1200px down) {
                width: rem-calc(40);
            }
            @include breakpoint(959px down) {
                margin:auto;
            }
        }
        .accordion-item {
            border-bottom: solid 1px #cccccc;
            padding-bottom: rem-calc(30);
            margin-bottom: rem-calc(30);
            @include breakpoint(959px down) {
                border: solid 1px #cccccc;
                margin: 0;
                padding: rem-calc(20);
                display: flex;
                display: flex;
                align-items: center;
            }
            &:last-child {
                margin-bottom: 0;
                border: 0;
            }
        }
        .accordion-title {
            background: transparent;
            border: 0;
            padding: 0;
            padding-right: 20%;
            padding-left: rem-calc(80);
            @include breakpoint(1024px down) {
                padding-left: rem-calc(40);
                margin: 0;
                br {
                    display: none;
                }
            }
            @include breakpoint(959px down) {
                padding-right: 0;
                padding-left: rem-calc(10);
            }
            @include breakpoint(420px down) {
                padding-left: rem-calc(20);
            }
            &:hover {
                h3 {
                    color: $color-alt;
                }
            }
            > * {
                font-size: clamp(rem-calc(20), 5vw, rem-calc(24));
                line-height: clamp(rem-calc(30), 5vw, rem-calc(36));
                font-weight: $bold;
                text-align: left;
                letter-spacing: -0.025em;
                color: $color-txt;
                transition: color 300ms;
            }
        }
        .accordion-content {
            background: transparent;
            border: 0;
            padding: 0;
            margin-top: rem-calc(25);
            padding-left: rem-calc(80);
            padding-right: 20%;
            @include breakpoint(1024px down) {
                padding-left: rem-calc(40);
            }
            @include breakpoint(768px down) {
                padding: 0;
            }
            p {
                @include paragraphes;
            }
        }
        .accordion-title::before {
            right: 65px;
            @include breakpoint(960px down) {
                right: 0;
            }
            @include breakpoint(959px down) {
                display: none;
            }
        }
    }

    .voonooslider .accordion-item {
        display: flex;
        align-items: flex-start;
        .accordion-number { margin: rem-calc(5); }
    }


    .button {
        border: solid 7px $color-main;
        background: white;
        color: $color-main;
        @include breakpoint(959px down) {
            display: table;
            margin: rem-calc(30) auto rem-calc(40);
        }
        &:hover {
            background: $color-main;
            border: solid 7px white;
            color: white;
        }
    }

    .btn-mob {
        @include breakpoint(959px up) {
            display: none;
        }
    }

    .voonooslider {
        @include breakpoint(959px up) {
            display: none;
        }
    }

    .section__voonoo {
        @include breakpoint(959px down) {
            padding: 0;
        }
    }

    .voonoo__img {
        img {
            border-radius: rem-calc(5);
        }
        @include breakpoint(959px down) {
            padding: 0 rem-calc(20);
        }
    }
}

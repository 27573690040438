#section9 {
    background: url(../assets/courbe-footer.png) white;
    background-attachment: fixed;
    padding: rem-calc(160 0 80);

    @include breakpoint (960px down) {
        padding: rem-calc(60 0);
    }

    .section__title, .section__title > * {
        @include big-titles;
        strong {
            @include paragraphes;
            font-size: clamp(rem-calc(16), 5vw, rem-calc(24));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(36));
        }
    }



    .section__text {
        table {
            p {
                margin-top: 0;;
            }
            td {
                @include breakpoint (960px down) {
                    padding:rem-calc(20) !important;
                    width: 100% !important;
                    float:left;
                    video {
                        width: 100%;
                    }
                }
                @include breakpoint (425px down) {
                    video {
                        height: auto;
                    }
                }
            }
        }
        p {
            margin-top: rem-calc(50);
            @include paragraphes;
            font-weight: $regular;

            @include breakpoint (960px down) {
                margin-top: 0;
            }
        }

        ul {
            list-style: none;

            @include breakpoint (960px down) {
                margin: 0;
                margin-left: 3px;
            }

            li {
                @include paragraphes;
                font-weight: $regular;
                margin-bottom: rem-calc(5);
                padding-left: rem-calc(10);
                position: relative;

                &:before {
                    content: ">";
                    position: absolute;
                    left: rem-calc(-5);
                    top: 0;
                    transition: 300ms;
                    font-size: 1.125rem;
                    color: $secondary-color;
                    font-weight: 400;
                    letter-spacing: 0.025rem;
                    line-height: 1.875rem;
                    font-family: "Poppins";
                }
            }
        }
    }

    .number__container {
        position: relative;
        overflow: hidden;

        @include breakpoint (1201px up) {
            left: rem-calc(-40);
            max-width: 80%;
        }
    }

    .number__legend {
        color: $color-txt;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        text-align: center;
        letter-spacing: 0.025em;

        @include breakpoint (1200px down) {
            font-size: rem-calc(14);
            line-height: rem-calc(22);
        }
    }

    .number__number {
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .footerslider {


        &__maintitle {
            
            font-size: clamp(rem-calc(20), 5vw, rem-calc(30));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(40));
            letter-spacing: -0.025em;
            font-weight: $bold;
            margin:rem-calc(100) auto rem-calc(50);
            span {
                color:$color-main;
            }
        }
        .slick-list {
            padding: 0 !important;
        }

        
        .slick-slide {
            margin: 0 14px;
        }

        .slick-dots {
            position: absolute;
            left: 0;
            right: 0;
        }

        .slick-track {
        }

        &__item {

            display: flex !important;
            flex-direction: column;
            background: #f0f2f5;
            height: 100%;
            display: flex;
            padding: rem-calc(40 25 25);
            min-height: rem-calc(400);

            @include breakpoint (960px down) {
                padding: rem-calc(40);
            }
            @include breakpoint (767px down) {
                min-height: rem-calc(300);
                padding: rem-calc(40);
            }
        }

        &__img {
            img {
                margin: 0;
            }
        }

        &__txt {
            font-size: clamp(rem-calc(14), 5vw, rem-calc(18));
            line-height: clamp(rem-calc(26), 5vw, rem-calc(30));
            letter-spacing: -0.025em;
            font-weight: $regular;
            font-style: italic;
            text-align: left;
        }

        &__author {
            font-size: clamp(rem-calc(10), 5vw, rem-calc(14));
            line-height: clamp(rem-calc(20), 5vw, rem-calc(24));
            letter-spacing: -0.025em;
            font-weight: $regular;
            font-style: italic;
            text-align: left;
        }
    }




    .slick-arrow {
        top: 0;
        margin: auto;
        position: absolute;
        text-indent: -9999px;
        width: rem-calc(46);
        height: rem-calc(46);
        cursor: pointer;
        z-index:1 ;
        @include breakpoint (767px up) {
            bottom: 0;
        }
        @include breakpoint (767px down) {
            top:34%;
        }
    }

    .slick-prev.slick-arrow {
        left: -20px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAb1BMVEUAAADpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDqxBr++/Tz35ztzVX////6893257j89+n579H36sX146rx2ow/QwSDAAAAGHRSTlMACPra3rnmzppbTx+17oxyShgREItxSfE2Lf1pAAABK0lEQVRIx5WWWXKDMAxAhY1ZHLMESCIIJUnb+5+x07qdJsjyWO8z8yI0QguwZ7St0Y1SjTatHSFKVjuFTyhTZ7zcFUgoOuYPZ41B9DkUukKWijzgWGKE8vhqH3KMkh+InexnJBNK+Z9/hQlUf3aPSfS/qeg0PffpnDCR00/wIlUvvsPXyDHjjhoADCOv9/u6+8kAjIqxl41EVwPYsH1dHjMSLExh++2GAVpwGOAWttGBDtpXZlLgQuv3WBgbG6D2tqzIoKi++UwY/SKJ3oCm2byzuWtwkso4aDHAB1P3FqzkrVquxT59h5EWA5PekSYyHistaB0dvpkMn2i0O784csHiEKwl2dITrlTZwpadA9mxEZ4ySs8eSuEZ5o+8oUc+ymAn5z8h3GQH2PEFltiZ2Dln160AAAAASUVORK5CYII=') no-repeat;

        @include breakpoint (1024px down) {
            left: 0;
        }
    }

    .slick-next.slick-arrow {
        right: -20px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAclBMVEUAAADpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDtzVXqxBr++/T////257jz35z689389+n57s3146rx2ozfC+NlAAAAGnRSTlMACfrduebZmltPShC17s2McuAhHBcHi3Hxz/JUIIkAAAE3SURBVEjHlZbploIwDIVDKQVsEdxQg4DozPu/4izOcbBJsbk/e74TLmmWgi9b1tpUSlVG16WFRR0Kp3AmpYtDEE6aDImyJuHpVYqs0hUXOsegcvKB/RoXtN6/0tsUF5Vu5/Rug2+02c18UyfUz7//HCOUPzOIUVr9WUnj8PRh54iROv7WiX/zlxCe/YQvfHrqPwN8AQCanI7Dlcc1gFXI8B8sriyUyOg6jCxfQoss39+5P67BIauunxjegcEAf5s6cmjgRMlnQglfAQbVDTefV4CS6ApOQe/fsakZI8mMASfJu4Nacqs1WwRjgMYSrIqvSGVpAV/ufYBG7bUH6SbaHkmGkcoSUWs3wsEhH0vyoQfJ+T19TmQDW7YOZMtGtsrki1K+humS1/6S92D6hGjd4wnhWvqE+AJYoaa8ycRm8wAAAABJRU5ErkJggg==') no-repeat;

        @include breakpoint (1024px down) {
            right: 0;
        }
    }
}
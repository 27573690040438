/* -----------------------------------------
   Configuration de foundation
   Se référer au fichier : foundation-sites/scss/settings/_settings.scss
----------------------------------------- */
/* exemple :
$foundation-palette: (
  primary: #b32a85,
  secondary: #15c55e,
  success: #000000,
  warning: #ffc31f,
  alert: #4d0fc0,
);
$breakpoint-classes: (small medium large xlarge);
*/



$global-width:  rem-calc(1600);
$grid-container: $global-width;



$breakpoint-classes: (small medium large xlarge xxlarge);
$breakpoints: (
  small: 0,
  medium:768px,
  large:1200px,
  xlarge: 1440px,
  xxlarge: 1600px,
);


$foundation-palette: (
  primary: #4A2BD1,
  secondary: #edbe15,
  success: #07812a,
  warning: #e08576,
  alert: #4d0fc0,
);

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$primary-color: #4A2BD1;
$secondary-color: #edbe15;
$color-main: $primary-color;
$color-alt: $secondary-color;
$color-txt:#161616;
$primary-color: $color-main;
$secondary-color: $color-alt;
$grisclair:#f3f5f7;



$body-font-family: "Raleway", sans-serif;
$header-font-family: $body-font-family;

$alt-font:"Montserrat", sans-serif;
$third-font:"Poppins", sans-serif;



$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;




// 11. Button
// ----------
$button-font-family: inherit;
$button-font-weight: $extrabold;
$button-padding: rem-calc(15) rem-calc(25);
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: $color-alt;
$button-color: $white;
$button-color-alt: $primary-color;
$button-radius: rem-calc(30);
$button-border: 1px solid transparent;
$button-hollow-border-width: 7px;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.25rem,
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: 10%;
$button-hollow-hover-lightness: 10%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;
$button-responsive-expanded: false;



// 7. Accordion
// ------------
$accordion-plusminus: true;
$accordion-background: $white;
$accordion-item-color: $color-txt;
$accordion-title-font-size: clamp(rem-calc(20), 5vw, rem-calc(24));
$accordion-content-color: $color-txt;
$accordion-item-padding: rem-calc(20) rem-calc(40) rem-calc(20) 0;
$accordion-content-border: 0;
$accordion-content-padding: 0 20% 0 0 ;
$accordion-content-background: $white;
$accordion-item-background-hover: transparent;



// 13. Callout
// -----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-sizes: (
  "small": 0.2rem 1rem,
  "default": 0.2rem 1rem,
  "large": 0.2rem 1rem,
);
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;



// 47. Reveal
// ----------
$reveal-background: transparent;
$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-padding: $global-padding;
$reveal-border: 0;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black, 0.45);
/* -----------------------------------------
 Import de foundation - Modifier les includes si besoin
----------------------------------------- */

// Global configuration
$global-flexbox: true;
$xy-grid: true;
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
@include foundation-xy-grid-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Generic components
@include foundation-button;
//@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;

// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
//@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
@include foundation-top-bar;
/*rien ci-dessous // fin du fichier */

#section5 {
    padding: 0 rem-calc(95);

    @include breakpoint (1024px down) {
        padding: 0 rem-calc(25);
    }

    @include breakpoint (1024px down) {
        .nopad {
            width: 100%;
        }
    }

    @include breakpoint (768px down) {
        padding: 0;
        margin-bottom: rem-calc(100);
    }

    .section5-left {
        display: flex;
        align-items: center;
    }

    .section__title,
    .section__title>* {
        @include big-titles;
        margin-bottom: 0;

        @include breakpoint (768px down) {
            margin-bottom: rem-calc(20);
            padding-left: rem-calc(15);
        }
    }

    .bottomslider {

        .slick-list {
            padding: 0 10px 0 18px !important;
        }

        .slick-dots {
            position: absolute;
            left: 0;
            right: 0;
        }

        &__item {

            min-height: rem-calc(580);
            display: flex !important;
            flex-direction: column;
            place-content: center;
            align-items: center;
            border-right: dotted 8px #dbdbdb;
            border-right-style: dashed;
            height: 100%;
            display: flex;
            padding: rem-calc(20 60);

            @include breakpoint (768px down) {
                min-height: rem-calc(300);
            }
        }

        &__img {
            img {
                margin: auto;
            }
        }

        &__title {
            max-width: rem-calc(250);
            font-size: clamp(rem-calc(20), 5vw, rem-calc(24));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(36));
            letter-spacing: -0.025em;
            font-weight: $bold;
            text-align: left;
        }
    }




    .slick-arrow {
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        text-indent: -9999px;
        width: rem-calc(46);
        height: rem-calc(46);
        cursor: pointer;
        z-index:1 ;
    }

    .slick-prev.slick-arrow {
        left: -20px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAb1BMVEUAAADpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDqxBr++/Tz35ztzVX////6893257j89+n579H36sX146rx2ow/QwSDAAAAGHRSTlMACPra3rnmzppbTx+17oxyShgREItxSfE2Lf1pAAABK0lEQVRIx5WWWXKDMAxAhY1ZHLMESCIIJUnb+5+x07qdJsjyWO8z8yI0QguwZ7St0Y1SjTatHSFKVjuFTyhTZ7zcFUgoOuYPZ41B9DkUukKWijzgWGKE8vhqH3KMkh+InexnJBNK+Z9/hQlUf3aPSfS/qeg0PffpnDCR00/wIlUvvsPXyDHjjhoADCOv9/u6+8kAjIqxl41EVwPYsH1dHjMSLExh++2GAVpwGOAWttGBDtpXZlLgQuv3WBgbG6D2tqzIoKi++UwY/SKJ3oCm2byzuWtwkso4aDHAB1P3FqzkrVquxT59h5EWA5PekSYyHistaB0dvpkMn2i0O784csHiEKwl2dITrlTZwpadA9mxEZ4ySs8eSuEZ5o+8oUc+ymAn5z8h3GQH2PEFltiZ2Dln160AAAAASUVORK5CYII=') no-repeat;

        @include breakpoint (768px down) {
            left: 10px;
        }
    }

    .slick-next.slick-arrow {
        right: -20px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAclBMVEUAAADpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDpvwDtzVXqxBr++/T////257jz35z689389+n57s3146rx2ozfC+NlAAAAGnRSTlMACfrduebZmltPShC17s2McuAhHBcHi3Hxz/JUIIkAAAE3SURBVEjHlZbploIwDIVDKQVsEdxQg4DozPu/4izOcbBJsbk/e74TLmmWgi9b1tpUSlVG16WFRR0Kp3AmpYtDEE6aDImyJuHpVYqs0hUXOsegcvKB/RoXtN6/0tsUF5Vu5/Rug2+02c18UyfUz7//HCOUPzOIUVr9WUnj8PRh54iROv7WiX/zlxCe/YQvfHrqPwN8AQCanI7Dlcc1gFXI8B8sriyUyOg6jCxfQoss39+5P67BIauunxjegcEAf5s6cmjgRMlnQglfAQbVDTefV4CS6ApOQe/fsakZI8mMASfJu4Nacqs1WwRjgMYSrIqvSGVpAV/ufYBG7bUH6SbaHkmGkcoSUWs3wsEhH0vyoQfJ+T19TmQDW7YOZMtGtsrki1K+humS1/6S92D6hGjd4wnhWvqE+AJYoaa8ycRm8wAAAABJRU5ErkJggg==') no-repeat;

        @include breakpoint (768px down) {
            right: 10px;
        }
    }
}
/**
  * Usages :
   @include texte( rem-calc(12), #FF0000, 400, 0.05rem, rem-calc(12), $body-font-color, center );
   @include texte( rem-calc(12), #000000 );
   @include texte( rem-calc(12), $align: right, $color: green );
   @include texte( $align: right, $color: green, $size: 12 );
*/
@mixin texte(
    $size: null,
    $color: null,
    $weight: null,
    $spacing: null,
    $height: null,
    $family: null,
    $align: null,
    $transform: null
) {
    font-size: $size;
    color: $color;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: $height;
    font-family: $family;
    text-align: $align;
    text-transform: $transform;
}

/**
 * Importation de Foundation 6
*/
@mixin import-foundation(
  $flex: true,
  $prototype: false,
  $xy-grid: $xy-grid
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @if $xy-grid {
    $xy-grid: true !global;
  }


  // Grids
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }

  // Helpers
  @include foundation-float-classes;
  @if $flex { @include foundation-flex-classes; }
  @include foundation-visibility-classes;
  @if $prototype { @include foundation-prototype-classes; }
}


@mixin paragraphes {
    font-size: clamp(rem-calc(16), 5vw, rem-calc(18));
    line-height: clamp(rem-calc(30), 5vw, rem-calc(32));
    letter-spacing: -0.025em;
    font-weight: $semibold;
    text-align: left;
    color:$color-txt;
}

@mixin big-titles {
  font-size: clamp(rem-calc(36), 5vw, rem-calc(72));
  line-height: clamp(rem-calc(40), 5vw, rem-calc(80));
  letter-spacing: -0.03em;
  color: white;
  font-weight: $extrabold;
  text-align: left;
  color:$color-txt;
  margin-bottom: rem-calc(25);
  @include breakpoint (1200px down) {
      margin-bottom: rem-calc(40);
  }
  span {
      color:$color-main;
  }
  & > * { margin-bottom: 0; line-height: 1; }
}
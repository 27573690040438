.number__number {
    svg.radial-progress {
        height: auto;
        max-width: 200px;
        transform: rotate(-90deg);
        width: 170px;
    }
    svg.radial-progress circle {
        fill: white;
        // stroke: #fff;
        /* Circumference */
        stroke-width: 4;
    }
    svg.radial-progress circle.incomplete {
        opacity: 0.25;
    }
    svg.radial-progress circle.complete {
        stroke-dasharray: 360;
        stroke-dashoffset: 360;
        /* Circumference */
    }
    svg.radial-progress text {
        fill: $primary-color;
        text-anchor: middle;
        font-variant-numeric: lining-nums;
       // font-family: $font-noyhBold;
        font-weight: 800;
        font-size: rem-calc(12);
        line-height: rem-calc(30);
        letter-spacing: -0.075rem;   
    }
    /*** COLORS ***/
    /* Primary */
    svg.radial-progress circle {
        // stroke: #f34959;
    }
}
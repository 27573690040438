#section4 {
    padding: rem-calc(165 0 0);
    background: white;
    margin-bottom: rem-calc(100);

    @include breakpoint (1440px down) {
        padding-right: rem-calc(40);
    }

    @include breakpoint (1200px down) {
        padding: rem-calc(100 0 0);
    }

    @include breakpoint (960px down) {
        padding: rem-calc(60 0 0);
        margin-bottom: rem-calc(25);
    }

    .section__title,  .section__title > * {
        @include big-titles;
        max-width: 95%;
        strong {
            color: $color-alt;
        }
    }

    .section__text {
        p {
            @include paragraphes;
            font-size: clamp(rem-calc(20), 5vw, rem-calc(24));
            line-height: clamp(rem-calc(30), 5vw, rem-calc(36));
        }
    }
}

button.toggle-text {
    font-size: rem-calc(36);
    float: right;
}

span.toggle-text-container p {
    margin: 0;
}
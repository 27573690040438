#section7 {
    background:white;
    padding:rem-calc(160 0 80);
    @include breakpoint (1440px down) {
        padding-right: rem-calc(40);
    }
    @include breakpoint (1200px down) {
        padding: rem-calc(100 0 0);
    }
    @include breakpoint (960px down) {
        padding: rem-calc(60 0 0);
    }
    @include breakpoint (768px down) {
        padding-bottom: rem-calc(60);
        .button {
            display: table;
            margin:rem-calc(8) auto;
        }
    }
    .faq__title {
        @include big-titles;
        color:$color-main;
        margin-bottom: rem-calc(25);
        @include breakpoint (768px down) {
            margin-bottom: 0;
        }
    }

    .faqplus {
        border-color:$light-gray;
    }
}
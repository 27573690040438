/* Feuille de style des contenus gérés par le tinyMCE de la console */
.rte {
    ul, ol {
        list-style-position: inside;
    }
    p {
      font-size: rem-calc(14);
      line-height: rem-calc(24);
      color:$color-txt;
    }

    a {
      color:$primary-color;
      &:hover {
        color:$secondary-color;
      }
    }

    h2 {
      font-size: clamp(1.8rem, 5vw, 2rem);
      line-height: clamp(2.2rem, 5vw, 2rem);
      letter-spacing: -0.03em;
      font-weight: 700;
      text-align: left;
      color:$color-txt;
      margin:rem-calc(40 0 10);
    }


    .grid {
    display: grid;
    }

  .col2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include breakpoint(small only)  {
      grid-template-columns: 1fr;
    }
  }

  .col3 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @include breakpoint(small only) {
      grid-template-columns: 1fr;
    }
  }
  
  .col4 {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;


    @include breakpoint(medium down) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
  .col5 {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    @include breakpoint(medium down) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
  
  .col6 {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    @include breakpoint(medium down) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
}

.btnScrollToTop {
  position: fixed;
  right: rem-calc(80);
  bottom: rem-calc(40);
  border: none;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s linear;

  &.active {
    opacity: 1;
  }

}